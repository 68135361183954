let hospitalSituation = [
  {
    path: '/hospital_synopsis',
    name: '医院简介',
    component: () => import('@/pages/hospitalSituation/hospital_synopsis')
  },
  {
    path: '/hospital_honor',
    name: '医院荣誉',
    component: () => import('@/pages/hospitalSituation/hospital_honor')
  },
  {
    path: '/organization',
    name: '组织架构',
    component: () => import('@/pages/hospitalSituation/organization')
  },
  {
    path: '/hospital_culture',
    name: '组织架构',
    component: () => import('@/pages/hospitalSituation/hospital_culture')
  },
  {
    path: '/hospital_depart',
    name: '科室及专家',
    component: () => import('@/pages/hospitalSituation/hospital_depart')
  },
  {
    path: '/depart_detail',
    name: '科室详情',
    component: () => import('@/pages/hospitalSituation/depart_detail')
  },
  {
    path: '/doctor_detail',
    name: '医生详情',
    component: () => import('@/pages/hospitalSituation/doctor_detail')
  },
  {
    path: '/depart_doctor_detail',
    name: '医生详情',
    component: () => import('@/pages/hospitalSituation/depart_doctor_detail')
  },
  {
    path: '/news_center',
    name: '新闻中心',
    component: () => import('@/pages/hospitalSituation/news_center')
  },
  {
    path: '/depart_describe',
    name: '科室详情',
    component: () => import('@/pages/hospitalSituation/depart_describe')
  }
]

export default hospitalSituation
