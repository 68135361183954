<template>
  <div id="app">
    <HeadNavigation></HeadNavigation>
    <router-view></router-view>
    <BottomNavigation></BottomNavigation>
  </div>
</template>

<script>
import HeadNavigation from './components/HeadNavigation'
import BottomNavigation from './components/BottomNavigation'
export default {
  components: {
    HeadNavigation,
    BottomNavigation
  }
}
</script>

<style lang="less">
  body,html {
    font-size: 16px;
  }
* {
  margin: 0;
  padding: 0;
}
li {
  list-style: none;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
}
  .tips {
    width: 80%;
    margin-left: 10%!important;
    font-size: 18px;
    font-weight: bold;
    color: #4d4d4d;
    margin: 5px 0;
    margin-top: 20px;
  }
  .container {
    width: 80%;
    margin-left: 10%;
  }
  .content {
    width: 80%;
    margin-left: 10%;
  }
  .current_path {
    margin: 30px 0;
    font-size: 16px;
    color: #4d4d4d;
    margin-left: 8%;
  }
  .type_title {
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 22px;
  }
  .more {
    width: 100%;
    text-align: center;
    margin: 20px 0;
    span {
      display: inline-block;
      width: 120px;
      text-align: center;
      border: 1px solid #66A1CC;
      color: #66A1CC;
      border-radius: 10px;
      padding: 2px 0;
    }
  }
  .nothing {
    width: 100%;
    margin: auto;
    text-align: center;
    margin: 50px;
  }
  .nothing_list {
    width: 100%;
    text-align: center;
    margin: 100px 0;
  }
</style>
