<template>
  <div class="navigation">
    <div class="logo">
      <div class="hos_logo">
        <img src="/static/index/logo.png" alt="" />
      </div>
      <div class="hos_code">
        <img src="/static/index/qcode.png" alt="" />
      </div>
    </div>
    <div class="headnav">
      <ul>
        <template v-for="item in nav">
          <li
            v-if="!item.hideInNavBar"
            :key="item.id"
            class="single"
            @click="go_next(item.path)"
          >
            <span>{{ item.name }}</span>
            <ul>
              <template v-for="list in item.children">
                <li
                  v-if="!list.hideInNavBar"
                  @click="go_next(list.path, item.id, list.id)"
                  :key="list.id"
                >
                  {{ list.name }}
                </li>
              </template>
            </ul>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script>
import { nav } from "./nav";
export default {
  data() {
    return {
      nav: nav,
    };
  },
  methods: {
    go_next(path, fId, cId) {
      if (!path) {
        return;
      }
      if (cId == '92') {
        this.$router.push(
          "/article_detail?id=40289f3988805f4a018880702e340006"
        );
        return;
      }
      if (fId) {
        this.$router.push(path + `?adress=${cId || fId || ""}`);
        return;
      }
      this.$router.push(path);
    },
    get_nav() {
      this.$http.get("/index/getClassify").then((res) => {
        console.log(res);
      });
    },
  },
};
</script>

<style scoped lang="less">
.navigation {
  width: 100%;
  padding-top: 20px;
  .logo {
    width: 70%;
    margin-left: 15%;
    height: 70px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    .hos_logo {
      display: block;
      width: 550px;
      height: 47px;
      float: left;
      img {
        display: block;
        width: 100%;
        height: 100%;
        margin: 10px;
      }
    }
    .hos_code {
      display: block;
      width: 180px;
      height: 35px;
      float: right;
      margin-right: 10px;
      img {
        display: block;
        width: 100%;
        height: 100%;
        margin: 10px;
      }
    }
  }
  .headnav {
    width: 100%;
    /*height: 50px;*/
    padding: 0 15%;
    box-sizing: border-box;
    background: #d9efff;
    > ul {
      width: 100%;
      /*height: 50px;*/
      .single {
        width: 9%;
        position: relative;
        display: inline-block;
        color: #456f99;
        text-align: center;
        span {
          display: inline-block;
          text-align: center;
          /*line-height: 50px;*/
          margin: 15px 0;
        }
        ul {
          position: absolute;
          background: #1885f2;
          z-index: 100;
          top: 50px;
          width: 100%;
          display: none;
          box-shadow: 0px 6px 12px 0px rgba(0, 51, 102, 0.4);
          border-radius: 0 0 5px 5px;
          li {
            width: 100%;
            /*height: 50px;*/
            /*line-height: 50px;*/
            padding: 15px 0;
            color: darkcyan;
            text-align: center;
            color: #fff;
            border-top: 1px solid #4cb5ff;
          }
          li:hover {
            background: #205880;
            font-weight: 600;
          }
        }
      }
      .single:last-child {
        width: 15%;
      }
      .single:hover {
        background: #1885f2;
        color: #fff;
        span {
          color: #fff;
        }
        ul {
          display: block;
        }
      }
    }
  }
}
</style>
