
class Utils {
  getName (name) {
    let index = name.indexOf('->')
    return name.substr(index + 2, )
  }
  formatDate({ date = null, format = 'number', days = 0 } = { date: null, format: 'number', days: 0 }) {
    date = date ? new Date(date) : new Date()
    let month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : String(0) + (date.getMonth() + 1)
    let day = date.getDate() > 9 ? date.getDate() : String(0) + date.getDate()
    if (format === 'yy-mm-dd') {
      return date.getFullYear() + '-' + month + '-' + day
    } else if (format === 'yy/mm/dd') {
      return date.getFullYear() + '/' + month + '/' + day
    } else if (format === 'yy-mm-dd hh:mm') {
      return date.getFullYear() + '-' + month + '-' + day + ' ' + date.getHours() + ':' + date.getMinutes()
    } else {
      return date.getTime()
    }
  }
}

const utils = new Utils()
export default utils
