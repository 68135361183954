import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import {Service} from '@/http/axios.js'
import 'element-ui/lib/theme-chalk/index.css';
import utils from './utils/index'
import {getNameFromNavId} from '@/components/nav'
Vue.use(router)
Vue.use(ElementUI)
Vue.config.productionTip = false
Vue.prototype.$http = Service
Vue.prototype.utils = utils

Vue.filter('getNameFromNavIdFilter', getNameFromNavId)

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
