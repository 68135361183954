<template>
   <div class="bottomnav">
     <div>
       <div class="headline">
         <b>友情链接</b>
         <span>Links</span>
       </div>
       <div>
         <ul class="blogroll">
           <li>
             <img src="/static/index/blogroll1.png" alt="">
           </li>
           <li>
             <img src="/static/index/blogroll2.png" alt="">
           </li>
           <li>
             <img src="/static/index/blogroll3.png" alt="">
           </li>
           <li>
             <img src="/static/index/blogroll4.png" alt="">
           </li>
         </ul>
         <ul class="qcode">
           <li>
             <img src="/static/index/QR1.png" alt="">
           </li>
           <li>
             <img src="/static/index/QR2.png" alt="">
           </li>
           <li>
             <img src="/static/index/QR3.png" alt="">
           </li>
           <li>
             <img src="/static/index/QR4.png" alt="">
           </li>
         </ul>
         <div class="bottom_cop">
            <ul>
              <li>Copyright 1998－2010 Powered By 云南省艾滋病关爱中心 All Rights Reserved.</li>
              <li>滇ICP备15002478号-1</li>
             <!-- <li>公安备530118102000116</li>-->
              <li>地址：云南省石安公路28公里处</li>
              <li>艾滋病咨询电话:0871-68189832  0871-68189838</li>
              <li>院总值班电话：0871-68728000</li>
            </ul>
         </div>
       </div>
     </div>
   </div>
</template>

<script>
  export default {
    name: "bottom-navigation"
  }
</script>

<style scoped lang="less">
.bottomnav {
  width: 100%;
  overflow: hidden;
  border-top: 1px solid #199FFF;
  >div {
    width: 80%;
    margin-left: 10%;
    >div {
      width: 70%;
      margin-left: 15%;
    }
  }
  .blogroll {
    display: inline-block;
    width: 45%;
    height: 100%;
    margin-right: 5%;
    margin-top: 20px;
    li {
      display: inline-block;
      width: 45%;
      height: 50px;
      margin: 10px 2.5%;
      float: left;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
  .qcode {
    display: inline-block;
    width: 50%;
    li {
      display: inline-block;
      width: 20%;
      height: auto;
      margin: 20px 10px;
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }
  .headline {
    width: 70%;
    margin: 30px 15%;
    b {
      font-size: 20px;
    }
    span {
      font-family: Source Han Sans CN;
      margin-left: 10px;
      color: #999999;
      font-size: 12px;
    }
  }
  .bottom_cop {
    width: 100%;
    text-align: center;
    ul {
      width: 100%;
      li {
        display: inline-block;
        margin: 10px 30px;
        color: #999;
        font-size: 12px;
      }
    }
  }
}
</style>
