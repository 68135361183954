let medicalGuide = [
  {
    path: '/registration',
    name: '预约挂号',
    component: () => import('@/pages/medicalGuide/registration')
  },
  {
    path: '/visit_information',
    name: '出诊信息',
    component: () => import('@/pages/medicalGuide/visit_information')
  },
  {
    path: '/medicare',
    name: '医保服务',
    component: () => import('@/pages/medicalGuide/medicare')
  }
]

export default medicalGuide
