let home = [
  {
    path: '/',
    name: '首页',
    component: () => import('@/pages/home')
  },
  {
    path: '/about_us',
    name: '关于我们',
    component: () => import('@/pages/about_us')
  }
]

export default home
