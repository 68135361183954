export const nav = [
  {
    name: "首页",
    id: "1",
    path: "/",
  },
  {
    name: "医院概况",
    id: "2",
    children: [
      {
        name: "医院简介",
        path: "/hospital_synopsis",
        id: "21",
      },
      {
        name: "医院荣誉",
        path: "/hospital_honor",
        id: "22",
      },
      {
        name: "组织架构",
        path: "/organization",
        id: "23",
      },
      {
        name: "医院文化",
        path: "/hospital_culture",
        id: "24",
      },
    ],
  },
  {
    name: "就医指南",
    id: "3",
    children: [
      {
        name: "预约挂号",
        path: "/registration",
        id: "31",
      },
      {
        name: "出诊信息",
        path: "/visit_information",
        id: "32",
      },
      {
        name: "医保服务",
        path: "/medicare",
        id: "33",
      },
    ],
  },
  {
    name: "特色业务",
    id: "4",
    children: [
      {
        name: "感染性疾病学科",
        path: "/aizibin_science",
        id: "41",
      },
      {
        name:'结核病学科',
        path: '/aizibin_science',
        hideInNavBar: true,
        id: "49",
      },
      {
        name: "精神病学科",
        path: "/aizibin_science",
        id: "42",
      },
      {
        name: "内科外科",
        path: "/aizibin_science",
        id: "43",
      },
      {
        name: "传染病应急救治",
        path: "/public_service",
        id: "44",
      },
      {
        name: "公共卫生服务",
        path: "/public_service",
        id: "45",
      },
      {
        name: "司法鉴定",
        path: "/public_service",
        id: "46",
      },
      {
        name: "药物临床试验研究",
        path: "/public_service",
        id: "47",
      },
      {
        name: "传染病应急救治",
        path: "/public_service",
        id: "48",
        hideInNavBar: true,
      },
      {
        name: "艾滋病学科",
        path: "/aizibin_science",
        id: "F1",
        hideInNavBar: true
      },
      {
        name: "公共服务",
        path: "/public_service",
        id: "F2",
        hideInNavBar: true
      },
    ],
  },
  {
    name: "科室及专家",
    id: "5",
    children: [
      {
        name: "首页",
        path: "/hospital_depart",
        id: "51",
      },
    ],
  },
  {
    name: "新闻中心",
    id: "6",
    children: [
      {
        name: "院内新闻",
        path: "/news_center",
        id: "61",
      },
      {
        name: "通知公告",
        path: "/article",
        id: "62",
      },
      {
        name: "预、决算信息公开",
        path: "/article",
        id: "63",
        hideInNavBar: true,
      },
    ],
  },
  {
    name: "党建.群团",
    id: "7",
    path: "/party_building",
    children: [
      {
        name: '党建',
        path: '/party_building',
        hideInNavBar: true,
        id: "71",
      },
      {
        name: '群团工作',
        path: '/article',
        hideInNavBar: true,
        id: "72",
      },
      {
        name: '职工园地',
        path: '/article',
        hideInNavBar: true,
        id: "73",
      },
      {
        name: '扶贫工作',
        path: '/article',
        hideInNavBar: true,
        id: "74",
      }
    ]
  },
  /* {
    name: '行风建设'',
    children: [
      {
        name: '首页',
        path: '/hospital_ethics'
      }
    ]
  }, */
  {
    name: "科研教学",
    id: "8",
    children: [
      {
        name: "科教动态",
        path: "/article",
        id: "81",
      },
      {
        name: "科研工作",
        path: "/scientific_effort",
        id: "82",
      },
      {
        name: "继续教育",
        path: "/continuing_education",
        id: "83",
      },
      {
        name: "专项培训",
        path: "/training",
        id: "84",
      },
      {
        name: "院校教育",
        path: "/college_education",
        id: "85",
      },
    ],
  },
  {
    name: "科普健康",
    id: "9",
    children: [
      {
        name: "健康知识",
        path: "/article",
        id: "91",
      },
      {
        name: "艾滋病防治宣传教育基地",
        path: "/article",
        id: "92",
      },
    ],
  },
  {
    name: "预、决算信息公开",
    id: "10",
    path: "/article?adress=101",
    children: [
      {
        name: "预、决算信息公开",
        path: "/article",
        id: "101",
        hideInNavBar: true,
      },
    ],
  },
];

export function getNameFromNavId (id) {
    for (let i = 0; i < nav.length; i++) {
        if (nav[i].id == id) {
            return nav[i].name
        }
        if (nav[i].children) {
            for (let j = 0; j < nav[i].children.length; j++) {
                const c = nav[i].children[j];
                if (c.id == id) {
                    return `${nav[i].name}->${c.name}`
                }
            }
        }
    }
    return ''
}

export function getItemFromNavId (id) {
    for (let i = 0; i < nav.length; i++) {
        if (nav[i].id == id) {
            return nav[i]
        }
        if (nav[i].children) {
            for (let j = 0; j < nav[i].children.length; j++) {
                const c = nav[i].children[j];
                if (c.id == id) {
                    return c
                }
            }
        }
    }
    return {}
}
